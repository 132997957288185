import React from 'react';

interface IProps {
  card: {
    title: string;
    text: React.ReactNode;
    imgSrc: string;
    alt: string;
  };
}

const HomepageCard: React.FC<IProps> = ({ card }) => (
  <div className="homepage-card">
    <h3 className="upper">{card.title}</h3>
    {card.text}
    <div className="homepage-card-img-container">
      <img src={card.imgSrc} alt={card.alt} />
    </div>
  </div>
);

export default HomepageCard;
