import React from 'react';
import padlockIcon from '../../images/homepage/PadlockIcon.png';
import puzzleIcon from '../../images/homepage/PuzzleIcon.png';
import UsersIcon from '../../images/homepage/UsersIcon.png';
import DollarIcon from '../../images/homepage/DollarIcon.png';
import HomepageCard from './HomepageCard';

const wereGamers = "We're gamers too.";
const weListen =
  'We listen to our community and deliver updates that matter to players.';

const cards = [
  {
    title: 'Play-to-win',
    text: (
      <span>
        Free of unnecessary microtransactions, our games offer players the full
        experience and are supported by sensible business models.
      </span>
    ),
    imgSrc: DollarIcon,
    alt: '',
  },
  {
    title: 'Privacy-focused',
    text: (
      <>
        <span>Privacy is more important than ever.</span>
        <span>Your data stays on your device. Full stop.</span>
      </>
    ),
    imgSrc: padlockIcon,
    alt: '',
  },
  {
    title: 'Player-driven',
    text: (
      <>
        <span className="d-block d-lg-none">{`${wereGamers} ${weListen}`}</span>
        <span className="d-none d-lg-block">{wereGamers}</span>
        <span className="d-none d-lg-block">{weListen}</span>
      </>
    ),
    imgSrc: UsersIcon,
    alt: '',
  },
  {
    title: 'Genre-spanning',
    text: (
      <span>
        We create fresh experiences by bringing together all kinds of features
        from our favourite genres.
      </span>
    ),
    imgSrc: puzzleIcon,
    alt: '',
  },
];
const HomepageCards: React.FC = () => (
  <>
    {cards.map((card, index) => {
      const classes = 'vertical-divider d-none d-xl-block';

      return (
        <React.Fragment key={card.title}>
          <HomepageCard card={card} />
          {index < cards.length - 1 && <div className={classes} />}
        </React.Fragment>
      );
    })}
  </>
);

export default HomepageCards;
