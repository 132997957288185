import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { BgImage } from 'gbimage-bridge';
import { getImage } from 'gatsby-plugin-image';
import { finalOutpostUrl, minWidthLg } from '../../constants';

const HomepageBanner: React.FC = () => {
  const { mobileImage, desktopImage } = useStaticQuery(
    graphql`
      query {
        mobileImage: file(relativePath: { eq: "homepage/banner/mobile.png" }) {
          childImageSharp {
            gatsbyImageData
          }
        }
        desktopImage: file(
          relativePath: { eq: "homepage/banner/desktop.png" }
        ) {
          childImageSharp {
            gatsbyImageData(quality: 100)
          }
        }
      }
    `
  );

  const images: any = [
    getImage(mobileImage),
    {
      ...getImage(desktopImage),
      media: minWidthLg,
    },
  ];

  return (
    <BgImage image={images}>
      <Container fluid>
        <Row>
          <Col>
            <Container>
              <Row>
                <Col>
                  <div className="homepage-img-text">
                    <p className="upper very-bold">Games. No nonsense.</p>
                    <p className="middle-text">
                      We are independent game developers focused on delivering immersive games.
                      Without ads, or invasive monetisation strategies.
                    </p>
                    <p className="end-text">
                      
                    </p>

                    <Link to={finalOutpostUrl}>
                      <Button
                        variant="secondary"
                        className="homepage-img-button px-lg-5 py-3 very-bold"
                      >
                        View our games
                      </Button>
                    </Link>
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </BgImage>
  );
};

export default HomepageBanner;
