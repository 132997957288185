import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import FileField from './Fields/FileField';
import BotField from './Fields/BotField';
import NameField from './Fields/NameField';
import EmailField from './Fields/EmailField';
import SubmitBtn from './SubmitBtn';

const joinTheTeam = 'Join the team';

const ContactFormHomepage: React.FC = () => {
  const [subject, setSubject] = useState('');
  const subjectIsJoinTeam = subject === joinTheTeam;

  return (
    <Form
      netlify-honeypot="bot-field"
      data-netlify="true"
      name="contact-homepage"
      method="POST"
    >
      <input type="hidden" name="form-name" value="contact-homepage" />

      <BotField />

      <NameField />

      <EmailField />

      <Form.Group className="required" controlId="formSubject">
        <Form.Label>Subject</Form.Label>
        <Form.Control
          as="select"
          required
          placeholder=""
          defaultValue=""
          name="subject"
          custom
          onChange={(a) => setSubject(a.target.value)}
        >
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <option value="" disabled selected />
          <option>{joinTheTeam}</option>
          <option>Support</option>
          <option>Media</option>
          <option>Enquiry</option>
        </Form.Control>
      </Form.Group>

      <FileField hidden={!subjectIsJoinTeam} />

      <Form.Group
        className={`required ${subjectIsJoinTeam && 'd-none'}`}
        controlId="formMessage"
      >
        <Form.Label>Message</Form.Label>
        <Form.Control
          required={!subjectIsJoinTeam}
          as="textarea"
          name="message"
          rows={3}
        />
      </Form.Group>

      <SubmitBtn />
    </Form>
  );
};
export default ContactFormHomepage;
