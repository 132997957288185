import React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import Button from 'react-bootstrap/Button';
import { aboutUsUrl, teamFullNames } from '../../constants';

const peopleImg = 'people-img';

const SamImg = () => (
  <StaticImage
  className={peopleImg}
  src="../../images/people/sam.png"
  alt="" />
);
const BenCImg = () => (
  <StaticImage
    className={peopleImg}
    src="../../images/people/ben-c.png"
    alt=""
  />
);
const jjImg = () => (
  <StaticImage
    className={peopleImg}
    src="../../images/people/jj.png"
    alt=""
  />
);
const EwanImg = () => (
  <StaticImage
    className={peopleImg}
    src="../../images/people/ewan.png"
    alt=""
  />
);
const HarriettImg = () => (
  <StaticImage
    className={peopleImg}
    src="../../images/people/harriett.png"
    alt=""
  />
);

const teamMembers = [
  {
    name: teamFullNames.sam,
    ImgElement: SamImg,
    role: 'Founder',
    description:
      'Sam founded Exabyte Games and organises the strategic, technical and creative direction of the company.',
  },
  {
    name: teamFullNames.benC,
    ImgElement: BenCImg,
    role: 'Technology',
    description:
      'Ben leads the development team, managing our technical strategy and coding our games.',
  },
  {
    name: teamFullNames.ewan,
    ImgElement: EwanImg,
    role: 'Digital Marketing',
    description:
      'Ewan heads up marketing and PR, creating content and managing our websites and social media.',
  },
  {
    name: teamFullNames.harriett,
    ImgElement: HarriettImg,
    role: 'Graphic Design',
    description:
      'Harriett, our Graphic Designer skilled in animation and design, creates immersive worlds at Exabyte Games.',
  },
  {
    name: teamFullNames.jj,
    ImgElement: jjImg,
    role: 'QA Testing',
    description:
      'JJ is the QA tester at Exabyte Games, identifying bugs and improving the player experience.',
  },
];

const TeamCard = ({ person }) => {
  const { ImgElement, name, role, description } = person;
  return (
    <div className="person-card">
      <ImgElement />
      <p className="upper person-name">{name}</p>
      <p className="upper person-role">{role}</p>
      <p className="person-description">{description}</p>
    </div>
  );
};

const MeetTheTeam: React.FC = () => (
  <div className="d-flex flex-column">
    <div className="meet-the-team-container">
      {teamMembers.map((person) => (
        <TeamCard key={person.name} person={person} />
      ))}
    </div>
    <Link className="meet-the-team-btn" to={aboutUsUrl}>
      <Button variant="primary">View About</Button>
    </Link>
  </div>
);

export default MeetTheTeam;
