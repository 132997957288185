import * as React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { StaticImage } from 'gatsby-plugin-image';
import { graphql, Link } from 'gatsby';
import HomepageCards from '../components/homepage/HomepageCards';
import PageWrapper from '../components/PageWrapper';
import SteamLogo from '../images/homepage/steam-logo.svg';
import Carousel from '../components/homepage/Carousel';
import Timeline from '../components/Timeline';
import MeetTheTeam from '../components/homepage/MeetTheTeam';
import ContactForm from '../components/contact-form/ContactHomepage';
import HomepageBanner from '../components/homepage/HomepageBanner';
import {
  BlogPost,
  homepageContactId,
  appStoreUrls,
  blogUrl,
} from '../constants';

const PreOrderButton = ({ className }) => (
  <a target="_blank" href={appStoreUrls.steam_homepage_wishlist_btn} rel="noreferrer">
    <Button variant="secondary" className={`very-bold upper p-3 ${className}`}>
      Wishlist now
    </Button>
  </a>
);

interface IProps {
  data: {
    blog: {
      posts: BlogPost[];
    };
  };
}

const IndexPage: React.FC<IProps> = ({ data }) => (
  <PageWrapper>
    <HomepageBanner />
    <Container fluid className="homepage-cards-container">
      <Row>
        <Col className="homepage-cards">
          <HomepageCards />
        </Col>
      </Row>
    </Container>
    <Container fluid>
      <Row className="homepage-latest-news upper">
        <Col>
          <h2>News</h2>
        </Col>
      </Row>
      <Row className="homepage-latest-news-steam">
        <div className="d-none d-md-inline-block homepage-latest-news-img-wrapper">
          <StaticImage
            style={{ position: 'absolute' }}
            className="homepage-steam-artwork-desktop"
            src="../images/homepage/steam-artwork-desktop.png"
            alt="Final Outpost Steam artwork"
          />
        </div>
        <StaticImage
          className="d-md-none homepage-steam-artwork-mobile"
          src="../images/homepage/steam-artwork-mobile.png"
          alt="Final Outpost Steam artwork"
        />
        <Col className="news-banner-content-col">
          <Container className="news-banner-content-container">
            <Row className="justify-content-md-center">
              <Col
                xs={12}
                md={6}
                xl={{ span: 4, offset: 1 }}
                className="mr-auto"
              >
                <div>
                  <h3 className="upper">Now on Steam</h3>
                  <p>
                    Final Outpost: Definitive Edition is coming to PC, Mac, Linux, Android and iOS.
                  </p>
                  <p>
                    Wishlist the game on Steam!
                  </p>
                  <PreOrderButton className="d-none d-md-block" />
                </div>
              </Col>
              <Col
                xs={12}
                md={{ span: 5, offset: 0 }}
                xl={6}
                className="img-container"
              >
                <a
                  className="homepage-google-play-logo align-bottom mr-auto"
                  target="_blank"
                  href={appStoreUrls.steam_homepage_logo_btn}
                  rel="noreferrer"
                >
                  <img src={SteamLogo} alt="" />
                </a>
              </Col>
            </Row>
            <Row className="justify-content-md-left">
              <Col md={8} xl={{ span: 4, offset: 1 }}>
                <PreOrderButton className="d-md-none" />
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
      <Row className="homepage-carousel-container">
        <Col className="flex-column justify-content-center">
          <Carousel
            className="carousel-container-mobile"
            visibleSlides={1}
            slideHeight={580}
            slideWidth={490}
            posts={data.blog.posts}
          />
          <Carousel
            className="carousel-container-tablet"
            visibleSlides={2}
            slideHeight={520}
            slideWidth={490}
            posts={data.blog.posts}
          />
          <Carousel
            className="carousel-container-desktop"
            visibleSlides={3}
            slideHeight={130}
            slideWidth={100}
            posts={data.blog.posts}
          />
          <Link className="d-block text-center mt-5" to={blogUrl}>
            <Button variant="primary">View All</Button>
          </Link>
        </Col>
      </Row>
    </Container>
    <Container fluid className="timeline-section-container">
      <Row>
        <Col>
          <Container>
            <Row>
              <Col>
                <h2 className="upper">Timeline</h2>
                <Timeline />
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
    <Container fluid className="team-section-container">
      <Row>
        <Col>
          <Container>
            <Row>
              <Col>
                <h2 className="upper">Meet The Team</h2>
                <MeetTheTeam />
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
    <Container fluid className="contact-section-container">
      <Row>
        <Col>
          <Container>
            <Row>
              <Col>
                <h2 id={homepageContactId} className="upper">
                  Contact Us
                </h2>
                <ContactForm />
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  </PageWrapper>
);

export const pageQuery = graphql`
  query MyQuery4 {
    blog: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      posts: nodes {
        frontmatter {
          date(fromNow: true)
          title
          category
          coverImage {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        fields {
          slug
        }
        shortExcerpt: excerpt(pruneLength: 120)
        longExcerpt: excerpt(pruneLength: 200)
        id
      }
    }
  }
`;

export default IndexPage;
