import React from 'react';
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { GatsbyImage } from 'gatsby-plugin-image';
import Card from 'react-bootstrap/Card';
import { BlogPost } from '../../constants';
import { getPrettyCategory } from '../../utils';
import LeftArrow from '../../images/arrows/left-white.svg';
import RightArrow from '../../images/arrows/right-white.svg';

const SlideCard = ({ post }) => {
  const { shortExcerpt, longExcerpt, fields, frontmatter } = post;
  const { coverImage, date, title, category } = frontmatter;
  const Img = () => (
    <GatsbyImage
      image={coverImage.childImageSharp.gatsbyImageData}
      alt=""
      className="carousel-img"
    />
  );
  return (
    <Card className="round">
      <a
        href={fields.slug}
        className="anchor-no-decoration round d-flex flex-column full-height"
      >
        <Card.Img as={Img} />
        <Card.Body className="d-flex flex-column py-4">
          <Card.Subtitle className="carousel-card-subtitle mt-2 mt-sm-3">
            <span className="upper very-bold">
              {getPrettyCategory(category)}
            </span>{' '}
            | <span className="upper very-bold">{date}</span>
          </Card.Subtitle>
          <Card.Title className="upper mt-1 mb-0">{title}</Card.Title>
          <Card.Text className="d-lg-none mt-3">{shortExcerpt}</Card.Text>
          <Card.Text className="d-none d-lg-block mt-3">
            {longExcerpt}
          </Card.Text>
        </Card.Body>
      </a>
    </Card>
  );
};

interface CarouselProps {
  className: string;
  slideWidth: number;
  slideHeight: number;
  visibleSlides: number;
  posts: BlogPost[];
}

const Carousel: React.FC<CarouselProps> = ({
  className,
  slideWidth,
  slideHeight,
  visibleSlides,
  posts,
}) => (
  <div className={`${className}`}>
    <CarouselProvider
      naturalSlideWidth={slideWidth}
      naturalSlideHeight={slideHeight}
      totalSlides={posts.length}
      visibleSlides={visibleSlides}
      isIntrinsicHeight
    >
      <Slider>
        {posts.map((post, index) => (
          <Slide key={post.id} index={index}>
            <SlideCard post={post} />
          </Slide>
        ))}
      </Slider>
      <ButtonBack className="carousel__button left carousel-arrow-btn">
        <img src={LeftArrow} alt="Left arrow" />
      </ButtonBack>
      <ButtonNext className="carousel__button right carousel-arrow-btn">
        <img src={RightArrow} alt="Right arrow" />
      </ButtonNext>
    </CarouselProvider>
  </div>
);

export default Carousel;
